import type { GetStaticPaths, GetStaticPropsContext } from 'next';

import { PageCategory } from '~/features/analytics/constants';
import { getCatalogItemsFromServer } from '~/features/catalog/controllers/get-catalog-items-from-server';
import * as HomepageCopyTest from '~/features/experiments/homepage-hero-copy';
import { DEFAULT_LANGUAGE } from '~/features/internationalization/languages';
import { PageHead } from '~/features/page/components/page-head';
import { PageLayout } from '~/features/page/components/page-layout';
import { getPage } from '~/features/page/controllers/get-page';
import { Page } from '~/features/page/models/page';
import { getPageTemplate } from '~/features/page-template/controllers/get-page-template';
import { Home, ProgramCollectionWithCatalogData } from '~/features/page-template/templates/home/components/home';
import { HomeTemplate, homeTemplateQuery, homeTemplatesSchema } from '~/features/page-template/templates/home/model';
import { getNewProgramsList } from '~/features/program/controllers/get-new-programs-list';

export default function HomePage({
  page,
  homeTemplate,
  newPrograms,
  programCollections,
}: {
  page: Page;
  homeTemplate: HomeTemplate;
  newPrograms: ProgramCollectionWithCatalogData;
  programCollections: ProgramCollectionWithCatalogData[];
}) {
  return (
    <>
      <PageHead
        page={page}
        pageCategory={PageCategory.Home}
        nextHeadChildren={
          <>
            <link as='image' href='/videos/waves-poster.webp' rel='preload' type='image/webp' />
          </>
        }
      />
      <PageLayout withoutVerticalPadding>
        <Home homeTemplate={homeTemplate} newPrograms={newPrograms} programCollections={programCollections} />
      </PageLayout>
    </>
  );
}

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [
      {
        params: {
          experimentKey: HomepageCopyTest.key,
        },
        locale: DEFAULT_LANGUAGE,
      },
    ],
    fallback: 'blocking',
  };
};

export async function getStaticProps({ locale, params }: GetStaticPropsContext) {
  // Stop additional locale pages except the default locale from being built
  if (locale !== DEFAULT_LANGUAGE || params?.experimentKey !== HomepageCopyTest.key) {
    return {
      notFound: true,
    };
  }

  const [page, homeTemplate, newProgramKeys] = await Promise.all([
    getPage('/'),
    getPageTemplate<HomeTemplate>({
      templateName: 'Home Page',
      templateQuery: homeTemplateQuery,
      templateZodSchema: homeTemplatesSchema,
    }),
    getNewProgramsList(),
  ]);

  const newPrograms = { title: 'Newly Released Courses', programs: getCatalogItemsFromServer(newProgramKeys ?? []) };

  let programCollections: ProgramCollectionWithCatalogData[] =
    homeTemplate.programCollections?.map((programCollection) => {
      const programKeys = programCollection.programs.map((program) => program.key);

      return {
        title: programCollection.title,
        programs: getCatalogItemsFromServer(programKeys),
      };
    }) ?? [];

  return {
    props: {
      page,
      homeTemplate,
      newPrograms,
      programCollections,
    },
  };
}
